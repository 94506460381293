<template>
  <div>

    <role-add-new :is-add-new-role-sidebar-active.sync="isAddNewRoleSidebarActive" :form-data-edit="formDataEdit"
      :form-disabled="formDisabled" @fetch-roles="fetchRoles" />
    <role-add-store :is-add-new-role-sidebar-active.sync="showModalStore"  @fetch-roles="fetchRoles" />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="3" class="">
            <label>Mostrar</label>
            <v-select v-model="tableSettings.perPage" :options="perPageOptions" :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>registros</label>
          </b-col>
          <b-col cols="12" md="9">
            <div class="d-flex align-items-center justify-content-end">
              <b-col md="4" class="offset-8">
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Buscar" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Borrar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-button variant="primary" class="btn-icon rounded-circle mr-1" @click="showModalStore = true"
                v-if="permissions.includes('Crear roles')">
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </b-col>



          <!-- Search -->
        </b-row>

      </div>

      <b-table ref="refRoleListTable" class="position-relative" :items="dataTable" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="tableSettings.sortBy" show-empty empty-text="No se encontraron datos"
        :sort-desc.sync="tableSettings.sortDesc" :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered">

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="detailRole(data.item)" v-if="permissions.includes('Ver roles')">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Detalle</span>
            </b-dropdown-item>

            <b-dropdown-item @click="editRole(data.item)" v-if="permissions.includes('Editar roles')">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteRole(data.item)" v-if="permissions.includes('Eliminar roles')">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Viendo del {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }}
              registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="tableSettings.page" :total-rows="totalRows" :per-page="tableSettings.perPage"
              first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup,  BInputGroup,
    BInputGroupAppend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import RoleAddNew from './RoleAddNew.vue'
import RoleAddStore from './RoleAddStore.vue'

export default {
  name: 'Role',
  components: {
    RoleAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    RoleAddStore
  },
  data() {
    return {
      showModalStore: false,
      filter: null,
      formDisabled: false,
      isAddNewRoleSidebarActive: false,
      refRoleListTable: null,
      perPageOptions: [25, 50, 100],
      searchQuery: '',
      inputs: [
        { value: 'id', title: 'Id' },
        { value: 'name', title: 'Rol' },
      ],
      typeSearch: [
        { value: 'LIKE', title: 'Igual' },
        { value: 'NOT LIKE', title: 'No es igual' },
        { value: '>', title: 'Mayor que' },
        { value: '<', title: 'Menor que' }
      ],
      tableColumns: [
        { key: 'name', label: 'Rol', sortable: true },
        { key: 'countPermissions', label: 'Permisos asignados' },
        { key: 'actions', label: 'acciones' },
      ],
      sortBy: 'id',
      isSortDirDesc: true,
      totalRows: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      dataTable: [],
      filterOn: [],
      showLoadingTable: false,
      tableSettings: {
        filter: this.$route.params && this.$route.params.id,
        searchQuery: '',
        input: '',
        typeSearch: '',
        perPage: 25,
        page: 1,
        sortBy: 'id',
        sortDesc: true,
      },
      formDataEdit: {},
      userData: null,
      permissions: []
    }
  },
  watch: {
    "tableSettings.sortBy": {
      handler(val) {
        this.fetchRoles()
      },
    },
    "tableSettings.sortDesc": {
      handler(val) {
        this.fetchRoles()
      },
    },
    "tableSettings.perPage": {
      handler(val) {
        this.fetchRoles()
      },
    },
    "tableSettings.searchQuery": {
      handler(val) {
        this.fetchRoles()
      },
    },
    "tableSettings.page": {
      handler(val) {
        this.fetchRoles()
      },
    },
  },
  created() {
    this.fetchRoles()
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.permissions = this.userData.permissions
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    fetchRoles() {
      this.showLoadingTable = true
      this.$http.get('/roles/list', { params: this.tableSettings }).then((response) => {
        this.dataTable = response.data.roles
        this.totalRows = response.data.total
        this.dataMetaCounter()
        this.showLoadingTable = false
      })
    },
    dataMetaCounter() {
      const localItemsCount = this.dataTable.length
      this.dataMeta.from = this.tableSettings.perPage * (this.tableSettings.page - 1) + (localItemsCount ? 1 : 0)
      this.dataMeta.to = this.tableSettings.perPage * (this.tableSettings.page - 1) + localItemsCount
      this.dataMeta.of = this.totalRows
    },
    detailRole(role) {
      this.formDisabled = true
      this.formDataEdit = role
      this.isAddNewRoleSidebarActive = true
    },
    editRole(role) {
      this.formDisabled = false
      this.formDataEdit = role
      this.isAddNewRoleSidebarActive = true
    },
    deleteRole(role) {
      this.$swal({
        title: `Esta séguro de eliminar el Rol ${role.name}?`,
        text: "Este proceso no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post('/roles/delete/' + role.id).then(() => {
            this.fetchRoles()
            this.$swal({
              icon: 'success',
              title: 'Rol eliminado!',
              text: 'El rol ha sido eliminado.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Eliminación cancelada',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
